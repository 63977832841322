import React, { useEffect, useState } from "react";
import "./style.css";
import ImageCarousel from "../../components/ImageSlideShow/SlideShow";
import { Message } from "./../../lib/getMessage";

const BannerImages = {
  InfraStructure:
    "https://storage.googleapis.com/zopping-uploads/originals%2F20230412%2Finfrastructure-20230412-070737.webp",
  InfraStructureMob:
    "https://storage.googleapis.com/zopping-uploads/originals%2F20230412%2Finfrastructuremobile-20230412-070736.webp",
  InfraStructureAr:
    "https://storage.googleapis.com/zopping-staging-uploads/1557/images/originals/20240426/0908f320-e538-487e-8745-6925f9cf0e36-14.webp",
  InfraStructureArMob:
    "https://storage.googleapis.com/zopping-staging-uploads/1557/images/originals/20240429/73735b0b-fcff-44f2-a400-04a8dc58afe7-Group1193x.webp",
  Cover:
    "https://storage.googleapis.com/zopping-uploads/originals%2F20230412%2Fcover-20230412-070736.webp",
  CoverMob:
    "https://storage.googleapis.com/zopping-uploads/originals%2F20230412%2Fcovermobile-20230412-070736.webp",
};
const HOME_BANNER = [
  {
    img: BannerImages.InfraStructure,
    imgMob: BannerImages.InfraStructureMob,
    title: <Message dictKey="banner.2.text" />,
    className: "infrastructure",
    imgMobAr: BannerImages.InfraStructureArMob,
    imgAr: BannerImages.InfraStructureAr,
    type: "imgWithText",
  },
  {
    img: BannerImages.Cover,
    imgMob: BannerImages.CoverMob,
    title: <Message dictKey="banner.1.text" />,
    className: "cover",
    type: "imgWithoutText",
  },
];
const Wrp = ({
  title,
  img,
  className,
  isMobile,
  imgMob,
  type,
  language,
  imgAr,
  imgMobAr,
}) => {
  const getimgsrc = (isMobile, lang) => {
    switch (lang) {
      case "ar":
        return isMobile ? imgMobAr : imgAr;
      default:
        return isMobile ? imgMob : img;
    }
  };
  const imgsrc =
    type === "imgWithText"
      ? getimgsrc(isMobile, language)
      : isMobile
      ? imgMob
      : img;

  return (
    <div
      className={`wrp ${className}`}
      style={{ width: "100%", height: "auto" }}
    >
      <img
        src={imgsrc}
        className="website-slideshow-img"
        alt="Banner-websites"
        style={{ width: "100%", height: "auto" }}
        loading="lazy"
      />
      <div className="title">{title}</div>
    </div>
  );
};
const HomeSlider = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window?.innerWidth < 480) {
      setIsMobile(true);
    }
  }, []);
  const { language } = props;
  return (
    <div className="home-page-slider" style={{ width: "100%", height: "auto" }}>
      <ImageCarousel>
        {HOME_BANNER.map((item) => (
          <Wrp {...item} isMobile={isMobile} language={language} />
        ))}
      </ImageCarousel>
    </div>
  );
};

export default HomeSlider;
