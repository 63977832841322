import React, { Component } from "react";
import Header from "../components/header";
import Hero from "../components/hero";
import WithProviders from "../components/WithProviders";
import { Message } from "../lib/getMessage";

import Retail from "./images/retail.png";
import Ecommerce from "./images/ecommerce.png";
import Logistics from "./images/logistics-icon.png";
import supplyChain from "./images/supply-chain.png";

import KrogerIcon from "./images/kroger.png";
import McafeeIcon from "./images/mcafee.png";
import GapIcon from "./images/gap.png";
import FairPriceIcon from "./images/fairPrice.png";
import RedMartIcon from "./images/redMart.png";
import GetzIcon from "./images/getz.png";
import TamimiIcon from "./images/tamimi.png";

import experience from "./images/experience.png";
import group from "./images/group.png";
import productcount from "./images/product-count.png";
import engineersCount from "./images/engineers-count.png";
import CPG from "./images/cpg.png";
import UiUx from "./images/ui-ux.png";
import cloud from "./images/cloud.png";

import "./widget.css";
import HomeSlider from "../components/HomeSlider";
import LayoutWrapper from "../components/layout";
import { CardLayoutWrapper } from "../components/CardLayouts";

const Widget = (props) => (
  <div className={`widget ${props.name}`}>
    <div className="widget-media">
      <img
        src={props.image.normal}
        srcSet={`${props.image.xx} 2x, ${props.image.xxx} 3x`}
        alt="media"
        loading="lazy"
      />
    </div>
    <div className="widget-details">
      <div className="header">
        <h2>{props.heading}</h2>
        <small>{props.type}</small>
      </div>
      <div className="details">
        <p>{props.details}</p>
      </div>
    </div>
  </div>
);

const Choice = (props) => (
  <div className={`choice ${props.name}`}>
    <div className="choice-media">
      <img src={props.image.normal} alt="media" loading="lazy" />
    </div>
    <div className="choice-details">
      <h4>{props.heading}</h4>
      {props.description && <p>{props.description}</p>}
      {props.line1 && <p>{props.line1}</p>}
      {props.line2 && <p>{props.line2}</p>}
    </div>
  </div>
);

const Extensions = (props) => (
  <div className="feature-box">
    <div className="feature-details">
      <div className="feature-table-name">
        <div className="name">
          <div
            className="feature-table-image"
            style={{ backgroundImage: `url(${props.image.normal})` }}
          />
          <div className="heading">{props.heading}</div>
        </div>
        <div className="explanation">{props.description}</div>
      </div>
      <div className="pricing">
        <div className="pricing-heading">{props.pricing.heading}</div>
        <div className="pricing-description">{props.pricing.description}</div>
      </div>
    </div>
  </div>
);

const IndustriesSpecialize = [
  { icon: Retail, text: <Message dictKey="industy.retail" /> },
  { icon: Ecommerce, text: <Message dictKey="industy.ecommerce" /> },
  { icon: Logistics, text: <Message dictKey="industy.logistics" /> },
  { icon: supplyChain, text: <Message dictKey="industy.supply.chain" /> },
];

const OurClients = [
  { icon: KrogerIcon },
  { icon: McafeeIcon },
  { icon: GapIcon },
  { icon: FairPriceIcon },
  { icon: RedMartIcon },
  { icon: GetzIcon },
  { icon: TamimiIcon },
];

const OurExpertiseData = [
  {
    description: <Message dictKey="yr.of.exp" />,
    image: experience,
  },
  {
    description: <Message dictKey="developing.refining.retail" />,
    image: group,
  },
  {
    description: <Message dictKey="digital.products.created" />,
    image: productcount,
  },
  {
    description: <Message dictKey="engineers.engaged.retail" />,
    image: engineersCount,
  },
  {
    description: <Message dictKey="strong.cleints" />,
    image: CPG,
  },
  {
    description: <Message dictKey="dedicated.UI/UX" />,
    image: UiUx,
  },
  {
    description: <Message dictKey="cloud.consulting.partners" />,
    image: cloud,
  },
];

class Bootstrap extends Component {
  componentDidMount() {
    if (
      window &&
      !window.location.href.includes("zopsmart") &&
      !window.location.href.includes("localhost")
    ) {
      window.location.replace("https://zopsmart.com/");
    }
  }
  render() {
    const { language, updateLanguage, direction } = this.props;
    const isDirectionRTL = direction === "rtl";
    return (
      <LayoutWrapper
        direction={direction}
        language={language}
        location={this.props.location}
      >
        <div>
          <Header
            current="home"
            language={language}
            updateLanguage={updateLanguage}
            direction={direction}
          />
          <HomeSlider language={language} />
           
          <Hero isDirectionRTL={isDirectionRTL} />
          <section
            className="spc-wrap"
            style={{ width: "100%", height: "auto" }}
          >
            <div className="specialize-heading">
              <p>
                <Message dictKey="home.industries.specialised" />
              </p>
            </div>
            <div className="ind-spc-wrp">
              <div className="ind-spc">
                {IndustriesSpecialize.map((item) => (
                  <div className="spc-grp">
                    <img
                      src={item.icon}
                      className="icon"
                      loading="lazy"
                      alt="industry-specialize"
                    />
                    <p className="desc">{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="our-clients">
            <div className="brd-future-heading">
              <h2>
                <Message dictKey="brands.helped" />
              </h2>
              <hr />
            </div>
            <div className="clients-icons">
              {OurClients.map((item) => (
                <div className="image-wrp">
                  <img
                    src={item.icon}
                    className="icon"
                    alt="brands"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          </section>
          <section className="expertise">
            <div className="expertise-heading">
              <h2>
                <Message dictKey="our.expert" />
              </h2>
              <hr />
            </div>
            <CardLayoutWrapper
              layoutData={OurExpertiseData}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
        </div>
      </LayoutWrapper>
    );
  }
}

export default WithProviders(Bootstrap);
export { Widget, Choice, Extensions };
