import React from "react";
import "./styles.css";

const CardLayout = (props) => (
  <div className="card-layout-wrp">
    <div className="card-layout">
      <div
        className={`card-image ${props.isDirectionRTL && "card-image-rtl"}`}
        style={{ backgroundImage: `url(${props.image})` }}
      />

      {props.heading && <div className="heading">{props.heading}</div>}
    </div>
    <div className="explanation">{props.description}</div>
  </div>
);

export const CardLayoutWrapper = ({ layoutData, isDirectionRTL }) => {
  return (
    <div className="cardWrapper">
      {layoutData.map((elm, idx) => {
        return (
          <CardLayout
            isDirectionRTL={isDirectionRTL}
            key={elm?.heading + idx}
            heading={elm?.heading}
            description={elm?.description}
            image={elm?.image}
          />
        );
      })}
    </div>
  );
};
